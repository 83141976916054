.ContentChat {
  background-color: #f5e6f5;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: stretch;
  FONT-FAMILY: IBM Plex Sans Devanagari;

}

.ContentChat::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.ContentChat {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.ContentChat::before {
  content: "";
  display: block;
  height: 20px;
  width: 100%;
}

.ContentChat::after {
  content: "";
  display: block;
  height: 20px;
  width: 100%;
}

.user {
  background-color: #dfb0df;
  align-self: flex-end;
  border-radius: 12px 12px 1px 12px;
  padding: 10px;
  font-size: 14px;
  max-width: 70%;
  margin-right: 10px;
  word-wrap: break-word;
  /* text-align: center; */
  letter-spacing: 0.2px;

}

.bot {
  background-color: #fff;
  align-self: flex-start;
  border-radius: 12px 12px 12px 1px;
  padding: 10px;
  margin-left: 25px;
  font-size: 14px;
  max-width: 70%;
  /* text-align: justify; */
  letter-spacing: 0.2px;
  word-break: break-word;
}

.bot-Container {
  align-self: flex-start;
  padding: 15px;
  margin-left: 33px;
  margin-right: 33px;
  margin-top: 10px;
}

.bot-speaker {
  display: flex;
  border-radius: 18px 18px 18px 1px;
  padding-bottom: 7px;
  margin-left: 20px;
  font-size: small;
  font-stretch: normal;
  margin-bottom: 15px;
  justify-content: space-between;
  max-width: 74%;
}

.timeuser {
  align-self: flex-end;
  /* padding: 5px; */
  font-size: 12px;
  margin-right: 10px;
  /* margin-top: -10px; */
  display: flex;
  justify-content: space-between !important;
}

.timebot {
  /* background-color: #ffffff; */
  align-self: flex-start;
  display: flex;
  /* border-radius: 15px 15px 1px 15px; */
  padding-left: 5px;
  font-size: 12px;
  margin-left: 25px;
  justify-content: space-between !important;
  margin-top: -18px;
  display: flex;
  flex-direction: row;
}

html,
body {
  overflow: hidden;
}

.dropDown {
  margin-top: 2px;
  width: 424px;
  font-size: medium;
  z-index: 103;
  display: flex;
  flex-direction: column;
  margin: auto;
  position: absolute;
  /* max-height: 80vh;
  min-height: 30vh; */
  height: 80vh;
  background-color: white;
  FONT-FAMILY: IBM Plex Sans Devanagari;

  bottom: 30px;
  left: 0;
  right: 0;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  padding-bottom: 8px;
  /* border-radius: 20px; */
}

.dropDown_ {
  margin-top: 2px;
  width: 424px;
  font-size: medium;
  FONT-FAMILY: IBM Plex Sans Devanagari;

  z-index: 103;
  display: flex;
  flex-direction: column;
  margin: auto;
  position: absolute;
  max-height: 80vh;
  min-height: 43vh;
  /* height: 44vh; */
  background-color: white;
  bottom: 30px;
  left: 0;
  right: 0;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  padding-bottom: 8px;
  /* border-radius: 20px; */
}

.closeIcon {
  align-self: end;
  display: flex;
  margin: 10px 10px 10px 0px;
}

.optionHeader {
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  font-family: IBM Plex Sans Devanagari;
}

.optionItem {
  /* border-top: 1px solid lightgray; */
  padding: 4px;
  font-size: 14px;
  text-align: center;
  margin: 4px;
  cursor: pointer;
}

/* Basic dropdown styling */
select {
  appearance: none;
}
.langOptions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Lang {
  width: 55px;
  height: 42px;
  margin-right: 5px;
  padding: 5px;
  padding-left: 10px;
  border: 1px solid #a91079;
  color: #a91079;
  border-radius: 10px;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  background-color: #e9c7f3;
}

.input-upload {
  position: absolute;
  left: 0;
  gap: 20px;
  right: 0;
  /* background-color: white; */
  display: flex;
  align-items: center;
  margin: 0 auto;
  max-width: 396px;
  padding: 15px;
}

.upload-container {
  display: flex;
  justify-content: center;
  margin-top: 2px;
}

/* Hide the input field */

/* Style the label to mimic a button */
.file-label {
  font-family: IBM Plex Sans Devanagari;
  /* display: inline-block; */
  padding: 10px;
  font-size: 16px;
  color: white;
  background-color: #91278f;
  width: 367px;
  /* height: 28px; */
  text-align: center;
  border-radius: 8px;
  border: none;
  /* cursor: pointer; */
}
.container-lang {
  /* height: fit-content;
  z-index: 130;
  background-color: white;
  display: flex;
  margin: auto;
  flex-direction: column;
  bottom: 30px;
 
  position: absolute;
  padding: 6px;
  align-items: start;
  margin: 0 auto;
  font-size: 20px; */

  margin-top: 2px;
  width: 424px;
  font-size: medium;
  z-index: 103;
  display: flex;
  flex-direction: column;
  FONT-FAMILY: IBM Plex Sans Devanagari;

  margin: auto;
  position: absolute;
  /* font-size: 20px; */
  /* max-height: 80vh;
  min-height: 30vh; */
  height: fit-content;
  background-color: white;
  bottom: 30px;
  left: 0;
  right: 0;
}

.optionContainer {
     /* background-color: #f8f6f4; */
    /* background-color: #f0d9f0; */
    align-self: center;
    border-radius: 18px 18px 18px 18px;
    padding: 5px;
    margin-left: 15px;
    color: #91278f;
    margin-right: 15px;
    min-width: 20%;
    height: -moz-fit-content;
    border: 2px solid #F9F9F9;
    height: fit-content;
    font-size: large;
    font-stretch: normal;
    margin-bottom: 10px;
}

.other-language {
  margin:  5px;
  padding: 10px;
  width: fit-content;
  border: 1px solid #a91079;
  border-radius: 30px;
  color: #a91079;
  background-color: #fff;
  font-size: 18px;
}

.selected-language {
  margin: 5px;
  padding: 10px;
  width: fit-content;
  border: 1px solid #a91079;
  border-radius: 30px;
  color: #a91079;
  font-weight: bold;
  font-size: 18px;

  background-color: #e9c7f3;
}

.schemesList {
  /* btn */
  padding: 5px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  max-width: 100%;
  min-width: 30%;
  font-weight: 700;
  margin: 0;
  background-color: #91278f;
  color: white;
  border-radius: 10px;
  margin: 10px;

  /* list */
  /* padding: 4px;
  font-size: 17px;
  cursor: pointer;
  max-width: 100%;
  min-width: 30%;
  font-weight: 700;
  margin: 0;
  padding: 7px;
  color: #91278F;
  border-radius: 10px; */
}

.read-more {
  display: block;
  margin: 10px auto;
  background-color: #91278f;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 15px;
}

.read-more:focus {
  outline: none;
}

.autoSuggesstionsContainer {
  margin-top: 2px;
  width: 100%;
  transform: translateY(-3px);
  FONT-FAMILY: IBM Plex Sans Devanagari;

  font-size: medium;
  z-index: 100;
  display: flex;
  flex-direction: column;
  margin: auto;
  position: absolute;
  max-height: 60vh;
  /* min-height: 30vh; */
  background-color: #fff;
  bottom: 87px;
  left: 0;
  right: 0;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding-bottom: 8px;
  cursor: pointer;
}

.autoSuggestionText {
  margin: 0px;
  padding: 4px 10px 0px 10px;
  overflow-wrap: break-word;
  color: #91278f;
  font-weight: 500;
  font-size: 14px;
}

hr {
  width: 95%;
  /* border: 1px solid #d8d9da; */
  background-color: #eeeeee;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
}

.addressSearch {
  margin-left: 17px;
  margin-right: 29px;
  padding: 12px;
  margin-bottom: 10px;
  border-radius: 10px;
  color: gray;
  font-size: large;
  border: 1px solid lightgrey;
}

.addressSearch:focus {
  outline: none;
}

.HospitalOptions {
  margin-top: 2px;
  width: 424px;
  font-size: medium;
  z-index: 103;
  display: flex;
  flex-direction: column;
  margin: auto;
  position: absolute;
  /* max-height: 80vh; */
  /* min-height: 36vh; */
  height: fit-content;
  background-color: white;
  bottom: 27px;
  left: 0;
  right: 0;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding-bottom: 8px;
}
.toggled {
  display: none;
}
.Hospitaldetails {
  font-size: 14px;

  padding: 10px;
  margin: 0px 0px 0px 15px;
  font-family: IBM Plex Sans Devanagari;
}

.BoxSentMSGSchemes {
  display: flex;
  align-items: center;
  /* width: 100%; */
  height: 50px;
  /* flex-direction: column; */
  /* padding-bottom: 10px; */
  /* padding-top: 10px; */
  padding: 0px 15px 10px 15px;
}

.langBtn {
  width: fitContent;
  height: 42px;
  margin-right: 5px;
  margin-left: 5px;
  padding: 10px;
  border: none;
  border-radius: 20px;
  font-size: 20px;
  background-color: #ededed !important;
  cursor: pointer;
  transition: background-color 0.3s;
}
.inputSchemes {
  font-family: IBM Plex Sans Devanagari;
  border: none;
  height: 42px;
  font-size: 20px;
  background-color: #f5e6f5;
  border-radius: 28px;
  width: 100%;
  /* border: none; */
  padding-left: 12px;
  outline: none;
  font-size: 1.1rem;
  
}




.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
  
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

/* media query for mobile layout */
@media only screen and (max-width: 600px) {
  .container-lang {
    /* height: fit-content; */
    /* font-size: 16px !important; */
    width: 100%;
    /* bottom: 3vh; */
  }

 

  .dropDown {
    width: 100%;
    /* bottom: 88px; */
  }
  .dropDown_ {
    width: 100%;
    min-height: 34vh;
  }
  .HospitalOptions {
    width: 100%;
  }
}