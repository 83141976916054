.textContainer {
  font-size: medium;
  z-index: 103;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 4px;
  position: absolute;
  color: black;
  height: -moz-fit-content;
  height: fit-content;
  background-color: white;
  bottom: 45%;
  left: 0;
  right: 0;
  padding-bottom: 8px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}

.headerFeedback1 {
  margin-top: 5px;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 5px;
}

.headerFeedback2 {
  font-size: 17px;
  color: gray;
  text-align: center;
  margin: 2px 0px 10px 0px;
}

.textarea {
  font-size: 17px;
  width: 90%;
  resize: none;
  border-color: 1px solid #91278F;
  overflow: auto;
  outline: none;
  align-items: center;
  justify-content: center;
  margin: 0px 20px 0px 20px;
  border: none;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.btn {
  border-radius: 8px;
  margin-right: 12px;
  font-size: 14px;
  padding: 5px 8px;
  cursor: pointer;
}

.cancel {
  border: 1px solid #91278F;
  background: white;
  color: #91278F;
}

.save {
  border: 1px solid #91278F;
  background: #91278F;
  color: #fff;
}

.disable {
  background-color: #e0e0e0;
  border: 1px solid #e0e0e0;
  color: #bcbcbc;
  cursor: not-allowed;
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin: 9px 3px 0px 0px;
}

.backDrop {
  position: absolute;
  inset: 0px;
  backdrop-filter: blur(5px);
  z-index: 999999;
}

.feedBackError {
  font-size: 17px;
  width: 90%;
  color: red;
  align-items: center;
  justify-content: center;
  margin: 0px 20px 0px 20px;
}
/* media query for mobile layout */
@media only screen and (max-width: 600px) {
  .textContainer {
    width: 100%;
  }
  .textarea {
    margin: 0px 14px 0px 14px;
  }
}
