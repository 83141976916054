.BoxSentMSG {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  /* flex-direction: column; */
  padding-bottom: 10px;
  padding-top: 10px;
}

.langBtn {
  width: fitContent;
  height: 42px;
  margin-right: 5px;
  margin-left: 5px;
  padding: 10px;
  border: none;
  border-radius: 20px;
  font-size: 20px;
  background-color: white !important;
  border : 1px solid #f5e6f5;
  cursor: pointer;
  transition: background-color 0.3s;
}
.input {
  font-family: IBM Plex Sans Devanagari;
  border: none;
  height: 42px;
  font-size: 20px;
  background-color: #ededed;
  border-radius: 28px;
  width: 100%;
  /* border: none; */
  padding-left: 12px;
  outline: none;
  font-size: 1.1rem;
}
