body {
  margin: 0;
    -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  padding: 0;
}


html {
  height: 100%;
  padding: 0;
  margin: 0;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Button,button,input{ */
  /* font-family: 'IBM Plex Sans Devanagari !important' */
/* } */
